<template>
  <div>
    <TransfertAdhesionsDepartemental />
  </div>
</template>

<script>
import TransfertAdhesionsDepartemental from '../../components/workflow/TransfertAdhesionsDepartemental';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TransfertAdhesionsDepartementalContainer',
  components: {
    TransfertAdhesionsDepartemental,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserNational: 'isUserNational',
    }),
  },

  created() {
    if (!this.isUserNational) {
      this.loadLotsTransfertAdhesionsDepartemental({ cdpeId: this.getPerimetreUser.perimetre_id });
      this.loadCdpeAdhesionEnLigneByCdpeId({ cdpeId: this.getPerimetreUser.perimetre_id });
    } else {
      this.loadFilterDepartements();
    }
  },

  methods: {
    ...mapActions({
      loadLotsTransfertAdhesionsDepartemental: 'loadLotsTransfertAdhesionsDepartemental',
    }),
    ...mapActions('cdpe', {
      loadFilterDepartements: 'loadFilterDepartements',
    }),
    ...mapActions('cdpe/adhesionenligne', {
      loadCdpeAdhesionEnLigneByCdpeId: 'loadByCdpeId',
    }),
  },
};
</script>
