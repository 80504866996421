<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="pa-5 mb-5">
            <v-card-title
              primary-title
              class="title"
            >
              Transfert des adhésions / abonnements vers le national
            </v-card-title>
            <div v-if="isUserNational">
              <div>
                <v-alert
                  color="primary"
                  dense
                  type="info"
                  :value="!currentCdpeId"
                >
                  Veuillez sélectionner un <strong>Département</strong>
                </v-alert>
                <div class="text-right">
                  <v-autocomplete
                    :items="filterDepartements"
                    label="Département"
                    :item-text="item => `${item.code} - ${item.nom}`"
                    item-value="id"
                    @change="changeDepartement"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="mb-5">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    Montant national :
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="text-right"
                  >
                    {{ totalNational | currency }}
                  </v-col>
                </v-row>
              </div>
              <v-btn
                color="primary"
                :disabled="canNotBeSend"
                @click.stop="showSendDialog = true"
              >
                Envoyer au National
              </v-btn>
              <v-btn
                v-if="showExportEbp"
                color="primary"
                class="ml-3"
                :disabled="!enabledExportLot || !enabledExportEbp"
                @click="exportEbp"
              >
                Export EBP
              </v-btn>
              <v-btn
                v-if="showExportPartsLocales"
                color="primary"
                class="ml-3"
                :disabled="!enabledExportLot || !enabledExportPartsLocales"
                @click="exportPartsLocales"
              >
                Export Versement Parts Locales
              </v-btn>
            </div>
          </div>
        </v-card>
        <div>
          <v-data-table
            v-model="selected"
            light
            :headers="headers"
            :items="lotsTransfertAdhesionsDepartemental"
            item-key="id"
            show-select
            class="elevation-3"
            :loading="loading"
            loading-text="Chargement en cours"
            no-data-text="Aucun lot à valider"
            sort-by="conseil_local.nom"
            :items-per-page="50"
            :footer-props="{
              itemsPerPageText: 'Lots par page:',
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
          >
            <v-progress-linear
              v-if="loading"
              v-slot:progress
              color="blue"
            />

            <template v-slot:no-data>
              Aucun lot à valider
            </template>

            <template v-slot:header.data-table-select>
              <v-checkbox
                :input-value="selectAllValue"
                :indeterminate="selectAllIndeterminate"
                @click.stop="toggleAll"
              />
            </template>

            <template v-slot:item.conseil_local.nom="{ item }">
              {{ item.conseil_local.nom }}
            </template>

            <template v-slot:item.totalParDestination.national="{ item }">
              {{ item.totalParDestination.national | currency }}
            </template>
          </v-data-table>

          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
      </v-col>
    </v-row>
    <Downloader
      :downloading-file="downloadingFile"
      :label="downloadingFileLabel"
      @downloadSuccess="onDownloadSuccess"
      @downloadError="ondownloadError"
    />
    <ConfirmedModal
      v-if="showSendDialog"
      :sentence="`Êtes-vous sûr de vouloir valider ces entrées et envoyer un règlement de ${this.$options.filters.currency(totalNational)} à la fédération nationale ?`"
      btn-action="Envoyer"
      @close="showSendDialog = false"
      @confirmed="sendToNational()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Downloader from '../partials/Downloader';
import ConfirmedModal from '../reusableComponents/confirmedModal';
import typeRecu from '../../config/typeRecuValidation';
import selectAllMixin from '../../mixins/vTableDataSelectAll';

export default {
  name: 'TransfertAdhesionsDepartemental',
  components: {
    Downloader,
    ConfirmedModal,
  },
  mixins: [selectAllMixin],
  data: () => ({
    headers: [
      {
        text: 'Lot',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Conseil Local',
        sortable: true,
        value: 'conseil_local.nom',
      },
      {
        text: 'Montant national',
        sortable: false,
        value: 'totalParDestination.national',
      },
    ],
    showSendDialog: false,
    currentCdpeId: 0,
    downloadingFile: null,
    downloadingFileLabel: null,
    enabledExportLot: false,
    enabledExportEbp: false,
    enabledExportPartsLocales: false,
  }),
  computed: {
    ...mapGetters({
      lotsTransfertAdhesionsDepartemental:
        'getLotsTransfertAdhesionsDepartemental',
      loading: 'getWorkflowLoading',
    }),
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserDepartemental: 'isUserDepartemental',
      isUserNational: 'isUserNational',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    ...mapState('cdpe/adhesionenligne', [
      'adhesionenligne',
    ]),
    items() {
      return this.lotsTransfertAdhesionsDepartemental;
    },
    totaux() {
      return (this.selected || []).reduce(
        (totaux, item) => ({
          national: totaux.national + item.totalParDestination.national,
        }),
        { national: 0 }
      );
    },
    totalNational() {
      return this.totaux.national;
    },
    canNotBeSend() {
      return (this.selected || []).length === 0;
    },
    showExportEbp() {
      return this.currentCdpeId !== 0;
    },
    showExportPartsLocales() {
      return this.currentCdpeId !== 0 && this.adhesionenligne && this.adhesionenligne.actif;
    },
  },
  watch: {
    lotsTransfertAdhesionsDepartemental(val) {
      this.selected = val.slice();
    },
  },
  created() {
    if (this.isUserDepartemental) {
      this.currentCdpeId = this.getPerimetreUser.perimetre_id;
    }
  },
  methods: {
    ...mapActions([
      'loadLotsTransfertAdhesionsDepartemental',
      'transfertAdhesionsDepartemental',
    ]),
    ...mapActions('cdpe/adhesionenligne', {
      loadCdpeAdhesionEnLigneByCdpeId: 'loadByCdpeId',
    }),
    changeDepartement(cdpeId) {
      this.currentCdpeId = cdpeId;
      this.loadLotsTransfertAdhesionsDepartemental({ cdpeId });
      this.loadCdpeAdhesionEnLigneByCdpeId({ cdpeId });
      this.enabledExportLot=false;
      this.enabledExportEbp=false;
      this.enabledExportPartsLocales=false;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    sendToNational() {
      this.showSendDialog = false;
      let lots = this.selected.map(lot => ({ id: lot.id }));
      this.transfertAdhesionsDepartemental({ lots }).then(response => {
        let paramsUrl = {
          typeValidation: typeRecu.VALIDATION_TRANSFERT_ADHESION_CDPE,
          listLotId: response.lots.map(e => e.id),
        };
        this.downloadingFileLabel = 'bordereau de validation';
        this.downloadingFile = {
          url: '/api/recuvalidation',
          params: paramsUrl,
        };
        this.enabledExportLot = response.lots[0].id;
        this.enabledExportEbp = true;
        this.enabledExportPartsLocales = true;
      });
    },
    onDownloadSuccess() {
      if (this.downloadingFile.url === '/api/recuvalidation') {
        this.loadLotsTransfertAdhesionsDepartemental({
          cdpeId: this.currentCdpeId,
        });
      }
      this.downloadingFile = null;
      this.downloadingFileLabel = null;
    },
    ondownloadError() {
      if (this.downloadingFile.url === '/api/recuvalidation') {
        this.loadLotsTransfertAdhesionsDepartemental({
          cdpeId: this.currentCdpeId,
        });
      }
      this.downloadingFile = null;
      this.downloadingFileLabel = null;
    },
    exportEbp() {
      this.downloadingFileLabel = 'export EBP';
      this.downloadingFile = {
        url: '/exportebp',
        params: { perimetreId: this.currentCdpeId },
      };
      this.enabledExportEbp = false;
    },
    exportPartsLocales() {
      this.downloadingFileLabel = 'export versement parts locales';
      this.downloadingFile = {
        url: `/api/resources/versement_parts_locales/${this.enabledExportLot}.zip`,
        method: 'GET',
      };
      this.on404Error = () => {
        console.log('Pas de part locale');
      };
      this.enabledExportPartsLocales = false;
    },
  },
};
</script>
